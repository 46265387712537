import * as React from 'react'
import cn from 'classnames'
import { OptionProps, components } from 'react-select'
import { useStore } from 'store/context'
import { UserOption } from '../user-select.types'
import { Avatar } from 'src/design-system'

export const Option = (props: OptionProps<UserOption>) => {
  const { data, children, isFocused, isSelected } = props

  const { currentUser } = useStore()
  const { user } = data

  const isCurrentUser = currentUser?.id === user.id

  return (
    <components.Option
      {...props}
      className={cn(
        isSelected && !isFocused && 'bg-theme-95',
        isFocused && 'bg-gray-50',
        'py-3 px-4 flex flex-row items-center gap-x-2 whitespace-nowrap text-gray-900'
      )}
    >
      <div className="relative">
        <Avatar
          size="xs"
          fallbackText={user.initials}
          src={user.avatarUrl}
          alt={`Avatar for ${user.fullName}`}
        />
      </div>
      <div>
        <span className="sr-only">Select </span>
        {children}
        {isCurrentUser && ' (You)'}
      </div>
    </components.Option>
  )
}
