import * as React from 'react'
import { PlaceholderProps, components } from 'react-select'
import { UserOption } from '../user-select.types'

export const Placeholder = (props: PlaceholderProps<UserOption>) => (
  <components.Placeholder
    {...props}
    innerProps={{
      className:
        'text-gray-400 relative flex flex-row items-center whitespace-nowrap',
    }}
  />
)
