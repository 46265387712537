import { Avatar } from 'src/design-system'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { MultiValueRemoveProps, components } from 'react-select'
import { UserOption } from '../user-select.types'

export const MultiValueRemove = ({
  data,
  innerProps,
  selectProps,
}: MultiValueRemoveProps<UserOption>) => (
  <components.MultiValueRemove
    data={data}
    innerProps={{
      ...innerProps,
      className: 'flex items-center justify-center rounded-full group',
    }}
    selectProps={selectProps}
  >
    <button type="button" className="relative group outline-none">
      <Avatar
        size="sm"
        fallbackText={data.user.initials}
        src={data.user.avatarUrl}
        alt={`Avatar for ${data.user.fullName}`}
      />
      <div className="absolute opacity-0 group-hover:opacity-100 group-focus:opacity-100 flex top-0 left-0 h-full w-full rounded-full transition-opacity bg-gray-100 text-gray-900 items-center justify-center">
        <X weight="bold" className="w-4 h-4" aria-hidden />
      </div>
    </button>
  </components.MultiValueRemove>
)
