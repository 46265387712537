import * as React from 'react'
import { MultiValueGenericProps, components } from 'react-select'
import { UserOption } from '../user-select.types'

export const MultiValueContainer = (
  props: MultiValueGenericProps<UserOption>
) => (
  <components.MultiValueContainer
    {...props}
    innerProps={{
      className: 'flex flex-row-reverse items-center gap-x-1 group',
    }}
  />
)
