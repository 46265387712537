import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import Select, { CSSObjectWithLabel } from 'react-select'
import * as Components from './components'
import { UserSelectVm } from './user-select-vm'
import { UserOption, UserSelectProps } from './user-select.types'

export const UserSelect = observer((props: UserSelectProps) => {
  const {
    value: externalValue = null,
    users,
    isMulti,
    placeholder = 'Add a person...',
    name = 'users',
    inputId = name,
    required = true,
    defaultValue,
    includeCurrentUser = true,
    onChange,
    className,
    isClearable,
    isDisabled,
    showAvatarOnControl = true,
    borderless = true,
    portalled,
    ...restProps
  } = props

  const [value, setValue] = React.useState(
    externalValue || defaultValue || null
  )

  React.useEffect(() => {
    setValue(externalValue || defaultValue || null)
  }, [externalValue, defaultValue])

  React.useEffect(() => {
    setValue((current) => {
      if (!current) return current
      if (!isMulti) return Array.isArray(current) ? current[0] : current
      return Array.isArray(current) ? current : [current]
    })
  }, [isMulti])

  const vm = React.useMemo(() => {
    return new UserSelectVm(users, isMulti, includeCurrentUser)
  }, [users, includeCurrentUser, isMulti])

  const { SingleValue, ...restComponents } = Components

  const portalProps = portalled
    ? {
        menuPortalTarget: document.body,
        styles: {
          menuPortal: (base: CSSObjectWithLabel) => ({
            ...base,
            // one more than radix portals
            zIndex: 2147483648,
            pointerEvents: 'all' as const,
          }),
        },
      }
    : {}

  return (
    <Select
      className={cn('user-select', className)}
      onChange={(newValue) => {
        if (isMulti) {
          const userIds = (newValue as UserOption[]).map(
            (option) => option.value
          )
          onChange?.(userIds)
          setValue(userIds)
        } else {
          const userId = (newValue as UserOption)?.value
          onChange?.(userId)
          setValue(userId)
        }
      }}
      defaultValue={vm.getOption(defaultValue)}
      value={vm.getOption(value)}
      name={name}
      inputId={name}
      options={vm.options}
      closeMenuOnSelect={!isMulti}
      blurInputOnSelect={!isMulti}
      isMulti={isMulti}
      required={required}
      placeholder={placeholder}
      isClearable={isClearable}
      isDisabled={isDisabled}
      borderless={borderless}
      components={{
        SingleValue: (props) => (
          <SingleValue showAvatarOnControl={showAvatarOnControl} {...props} />
        ),
        ...restComponents,
      }}
      {...portalProps}
      {...restProps}
    />
  )
})
