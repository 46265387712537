import { Avatar } from 'src/design-system'
import * as React from 'react'
import {
  components,
  SingleValueProps as BaseSingleValueProps,
} from 'react-select'
import { UserOption } from '../user-select.types'

type SingleValueProps = BaseSingleValueProps<UserOption> & {
  /**
   * Whether or not to show the avatar on the control input (even if false, the avatars in the option list will still show)
   */
  showAvatarOnControl?: boolean
}

export const SingleValue = (props: SingleValueProps) => {
  const {
    children,
    data: { user },
    showAvatarOnControl = true,
  } = props

  return (
    <components.SingleValue
      {...props}
      className="flex flex-row items-center gap-x-1"
    >
      {showAvatarOnControl && (
        <Avatar
          size="sm"
          fallbackText={user.initials}
          src={user.avatarUrl}
          alt={`Avatar for ${user.fullName}`}
        />
      )}
      {children}
    </components.SingleValue>
  )
}
