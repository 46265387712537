import { store } from 'store/index'
import { User } from 'store/modules/users'

export class UserSelectVm {
  constructor(
    private users: User[],
    private multi: boolean = false,
    private includeCurrentUser: boolean = true
  ) {}

  getOption(value: string | string[] | null | undefined) {
    if (!this.multi) {
      return this.options.find((option) => option.value === value) || null
    }

    const filteredOptions = this.options.filter((option) =>
      value?.includes(option.value)
    )

    return filteredOptions.length > 0 ? filteredOptions : null
  }

  get options() {
    const { currentUser } = store
    const users = this.includeCurrentUser
      ? this.users
      : this.users.filter((user) => user.id !== currentUser?.id)

    return users
      .map((user) => ({
        label: user.fullName,
        value: user.id,
        user,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }
}
