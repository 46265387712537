import * as React from 'react'
import { CaretDown, LockSimple } from '@phosphor-icons/react'
import { DropdownIndicatorProps, components } from 'react-select'
import { UserOption } from '../user-select.types'

export const DropdownIndicator = (
  props: DropdownIndicatorProps<UserOption>
) => {
  const { isDisabled } = props

  const Icon = isDisabled ? LockSimple : CaretDown

  return (
    <components.DropdownIndicator {...props}>
      <Icon weight="bold" className="text-gray-200 w-4 h-4" aria-hidden />
    </components.DropdownIndicator>
  )
}
