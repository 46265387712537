import { components, ControlProps, GroupBase } from 'react-select'
import * as React from 'react'
import cn from 'classnames'
import { UserOption } from '../user-select.types'

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    prefix?: string
    borderless?: boolean
  }
}

export const Control = (props: ControlProps<UserOption>) => {
  const { children, ...restProps } = props

  const prefix = props.selectProps.prefix
  const borderless = props.selectProps.borderless

  return (
    <components.Control
      className={cn(
        'flex flex-row items-center rounded text-sm px-3 shadow-none',
        borderless
          ? '-mx-3 border-[transparent]'
          : 'border-px border-solid !pl-0 !pr-1 !border-gray-100 h-full',
        props.isFocused ? '!border-theme-60' : 'border-[transparent]'
      )}
      {...restProps}
    >
      {prefix && (
        <span className="font-bold h-8 self-baseline flex items-center mt-1">
          {prefix}
        </span>
      )}
      {children}
    </components.Control>
  )
}
